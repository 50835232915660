<template>
  <b-form-group :label-for="name">
    <base-select
      selectClass="select-shadowed"
      :id="name"
      :name="name"
      v-model="_value"
      :options="options"
      :reduce="addrr => addrr.id"
      :clearable="false"
      >
    </base-select>
  </b-form-group>
</template>

<script>
import BaseSelect from '@/theme/components/Form/BaseSelect'
import { mapState } from 'vuex'
import { find } from 'lodash'
import { formatAddress } from '@/theme/helpers'

export default {
  name: 'SelectListAddress',
  props: {
    value: {
      required: true
    },
    name: {
      type: String,
      default: 'selectListAddress'
    }
  },
  computed: {
    ...mapState({
      addresses: state => state.user.current ? state.user.current.addresses.map(addrr => {
        return {
          ...addrr,
          label: formatAddress(addrr)
        }
      }) : []
    }),
    options () {
      return this.addresses.concat({
        id: null,
        label: this.$t('Add new address')
      })
    },
    _value: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
        this.addressSelected(newValue)
      }
    }
  },
  methods: {
    addressSelected (newValue) {
      const findAdd = find(this.options, { id: newValue })
      this.$emit('addressSelected', findAdd)
    }
  },
  components: {
    BaseSelect
  }
}
</script>
