import IntConfig from '../Config'

export function isApplePayAllowed () {
  console.log('try check apple  ', IntConfig.applePayVersion)
  if (window.ApplePaySession) {
    if (window.ApplePaySession && window.ApplePaySession.supportsVersion(IntConfig.applePayVersion)) {
      return true
    }
    return true
  }
  return false
}
