<template>
  <div class="wrapp-pickup-in-store">
    <template v-if="hasStoreSelected">
      <div class="wrapp-boutique">
        <div class="description-line store-name">
          <span class="title">
            {{ $t('pickup-from') }}
            <br>
            BOUTIQUE AQUAZZURA {{storeSelected.name}}
          </span>
        </div>
        <div class="description-line street-name">
          <span class="text">{{storeSelected.address}}, {{storeSelected.city}}, {{storeSelected.postcode}} {{storeSelected.region}}, {{storeSelected.country}}</span>
        </div>
        <div class="description-line phone-number line-with-label">
          <span class="label mr-1">{{ $t('phone') }}:</span>
          <span class="text">{{storeSelected.phone}}</span>
        </div>
        <div class="description-line email line-with-label">
          <span class="label mr-1">{{ $t('email') }}:</span>
          <span class="text">{{storeSelected.email}}</span>
        </div>
        <div class="description-line wh" v-if="openingStatement" :class="[openingClass]">
          <span class="open-close text-capitalize">
            {{ openingStatement.isOpen ? $t('is-open') : $t('closed') }}
          </span>
          <span class="divisor mx-1">|</span>
          <span class="open-close-at">
            {{openingStatement.isOpen ?
              `${$t('close-at')} ${openingStatement.closeAt }`
              :
              `${$t('open-at')} ${openingStatement.openAt }`
            }}
          </span>
        </div>
      </div>
    </template>
    <button class="btn btn-primary" @click.prevent="toggleModalStore">{{$t('pickup.select-store')}}</button>
    <modal-stores-pickup
    v-model="showModalPickup"
    :canSelectStore="true"
    @selectedStore="$emit('selectedStore', $event)"
    @close="toggleModalStore({ toggle: false })"
    />
  </div>
</template>

<script>
import { isOpenNow } from '@/theme/helpers'
import ModalStoresPickup from '@/theme/components/Modal/StoresPickup'

export default {
  name: 'PickupInStore',
  props: {
    hasStoreSelected: {
      type: Boolean,
      default: false
    },
    storeSelected: {
      type: Object
    }
  },
  data () {
    return {
      showModalPickup: false
    }
  },
  computed: {
    openingStatement () {
      if (Object.keys(this.storeSelected).length && this.storeSelected.working_hours) {
        return isOpenNow(this.storeSelected.working_hours)
      } else {
        return false
      }
    },
    openingClass () {
      if (this.openingStatement) {
        return this.openingStatement.isOpen ? 'is-valid' : 'is-invalid'
      } else {
        return {}
      }
    }
  },
  methods: {
    toggleModalStore ({ toggle = true }) {
      // call stores to show on modal
      this.showModalPickup = toggle
    }
  },
  components: {
    ModalStoresPickup
  }
}
</script>
