var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"text-left",attrs:{"novalidate":""}},[_c('section',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"id":"groupFirstName","label-for":_vm.name + '-firstname'}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":_vm.$t('address.FirstName') + ' *',"name":_vm.name + '-firstname',"autocomplete":"firstname","type":"text","validations":[
              {
                condition: _vm.errors.has(_vm.name + '-firstname'),
                text: _vm.errors.first(_vm.name + '-firstname')
              }
            ]},on:{"blur":_vm.formValidity},model:{value:(_vm.address.firstname),callback:function ($$v) {_vm.$set(_vm.address, "firstname", $$v)},expression:"address.firstname"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"id":"groupLastName","label-for":_vm.name + '-lastname'}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":_vm.$t('address.LastName') + ' *',"name":_vm.name + '-lastname',"autocomplete":"lastname","type":"text","validations":[
              {
                condition: _vm.errors.has(_vm.name + '-lastname'),
                text: _vm.errors.first(_vm.name + '-lastname')
              }
            ]},on:{"blur":_vm.formValidity},model:{value:(_vm.address.lastname),callback:function ($$v) {_vm.$set(_vm.address, "lastname", $$v)},expression:"address.lastname"}})],1)],1)],1),_c('address-autocomplete',{ref:"addressAutocomplete",attrs:{"name":_vm.name,"address":_vm.address},on:{"addressHasChanged":_vm.addressChange,"formValidity":_vm.formValidity}})],1),(_vm.isBilling)?[(_vm.enableReqInvoice)?_c('b-form-group',{attrs:{"id":"groupRequireInvoice","label-for":_vm.name + '-requireInvoice'}},[_c('base-check-box',{attrs:{"disabled":_vm.requiredReqInvoice,"name":_vm.name + '-requireInvoice',"use_prevent":false,"placeholder":_vm.$t('address.RequireInvoice')},model:{value:(_vm.requireInvoice),callback:function ($$v) {_vm.requireInvoice=$$v},expression:"requireInvoice"}})],1):_vm._e(),(_vm.requireInvoice)?[_c('b-form-group',{attrs:{"id":"groupTaxVat","label-for":_vm.name + '-taxid'}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.name + '-taxid',"autocomplete":"taxid","type":"text","placeholder":((_vm.$t('address.TaxId_VatId')) + " *"),"validations":[
            {
              condition: _vm.errors.has(_vm.name + '-taxid'),
              text: _vm.errors.first(_vm.name + '-taxid')
            }
          ]},on:{"blur":_vm.formValidity},model:{value:(_vm.address.vat_id),callback:function ($$v) {_vm.$set(_vm.address, "vat_id", $$v)},expression:"address.vat_id"}})],1)]:_vm._e()]:_vm._e(),_vm._t("footer",null,null,{ handleSubmit: _vm.handleSubmit })],2)}
var staticRenderFns = []

export { render, staticRenderFns }