<template>
  <div class="card-pickup">
    <div class="wrapp-pin">
      <span class="store-index"></span>
    </div>
    <div class="wrapp-info" @click.prevent="$emit('cardClicked', listIndex)">
      <span class="store-name">{{store.name}}</span>
      <div class="store-street">
        <span class="text">{{store.address}}, {{store.city}}, {{store.postcode}} {{store.region}}, {{store.country}}</span>
      </div>
      <div class="store-phone">
        <span class="label">{{ $t('phone') }}</span>
        <span class="value">{{store.phone}}</span>
      </div>
      <div class="store-email">
        <span class="label">{{ $t('email') }}</span>
        <span class="value">{{store.email}}</span>
      </div>
      <div class="store-wh" v-if="openingStatement" :class="[openingClass]">
        <span class="open-close text-capitalize">
          {{ openingStatement.isOpen ? $t('is-open') : $t('closed') }}
        </span>
        <span class="divisor mx-1">|</span>
        <span class="open-close-at">
          {{openingStatement.isOpen ?
            `${$t('close-at')} ${openingStatement.closeAt }`
            :
            `${$t('open-at')} ${openingStatement.openAt }`
          }}
        </span>
      </div>
      <div class="store-actions">
        <div class="store-type">
          {{ store.is_boutique == '1' ? $t('flagship-store') : $t('flagship-corner') }}
        </div>
        <div class="store-cta" v-if="canSelectStore">
          <button class="btn btn-primary" @click.prevent="$emit('selectStore', store)">{{ $t('pickup.select-this-store')}}</button>
        </div>
         <!-- <div v-else class="store-route">
          <a :href="calcRouteUrl" class="bcm-link bcm-link-primary" target="_blank">{{ $t('view-route') }}</a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CardPickup',
  props: {
    store: {
      type: Object,
      required: true
    },
    canSelectStore: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      markerIcon: require('@/theme/assets/image/maps/pin/pin.svg')
    }
  },
  computed: {
    openingStatement () {
      if (Object.keys(this.store).length && this.store.working_hours) {
        let workingHoursInfo = this.isOpenNow(this.store.working_hours)
        if (workingHoursInfo && workingHoursInfo.closeAt === '11:55 pm') {
          workingHoursInfo.closeAt = '12:00 am'
        }
        return workingHoursInfo
      } else {
        return false
      }
    },
    openingClass () {
      if (this.openingStatement) {
        return this.openingStatement.isOpen ? 'is-valid' : 'is-invalid'
      } else {
        return {}
      }
    },
    calcRouteUrl () {
      if (this.store) {
        return `//maps.google.com/maps?saddr=current+location&daddr=${this.store.country},${this.store.region},${this.store.city},${this.store.address}`
      }
      return null
    }
  },
  methods: {
    isOpenNow (workingHours) {
      if (workingHours) {
        let today = moment()
        if (workingHours['everyday']) {
          return {
            // Convert AM|PM to 24h to compare
            isOpen: moment(today).format('HH:mm') <= moment(workingHours['everyday'].to, 'hh:mm A').format('HH:mm'),
            closeAt: workingHours['everyday'].to,
            openAt: workingHours['everyday'].from
          }
        } else {
          for (let i in workingHours) {
            // look for today
            if (i === today.format('dddd').toLowerCase()) {
              return {
                // Convert AM|PM to 24h to compare
                isOpen: moment(today).format('HH:mm') <= moment(workingHours[i].to, 'hh:mm A').format('HH:mm'),
                closeAt: workingHours[i].to,
                openAt: workingHours[i].from
              }
            }
          }
        }
      }
    }
  }
}
</script>
